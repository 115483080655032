import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass'

const MultiplierBox = styled(Box)`
  margin: 10px;
  height: 50px;
  width: 50px
  border-radius: 10px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border: solid 2px;
`

const Input = styled.input`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
`

export default class Pock9 extends React.Component {
  state = localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state'))
    : {
        balance: 0,
        stake: 5,
        log: [],
      }

  setPersistentState(change) {
    this.setState(change, () => {
      localStorage.setItem('state', JSON.stringify(this.state))
    })
  }

  onWinLose(multiplier) {
    this.setPersistentState({
      balance: this.state.balance + this.state.stake * multiplier,
      log: this.state.log.concat({
        stake: this.state.stake,
        multiplier,
        priorBalance: this.state.balance,
      }),
    })
  }

  reset() {
    const isConfirm = window.confirm('Are you sure you want to reset balance?')
    if (isConfirm) {
      this.setPersistentState({
        balance: 0,
        stake: 5,
      })

      setTimeout(() => window.location.reload(), 10)
    }
  }

  render() {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Box mb={4}>
          <Text fontWeight={600} fontSize={28} textAlign="center">
            Pock9.com
          </Text>
          <Text fontWeight={400} fontSize={18} mt={3} textAlign="center">
            Made with ❤️ at Koh' Payam
          </Text>
        </Box>

        <Box mb={1}>
          <Text fontSize={16}>
            Your Balance:{' '}
            <button onClick={() => this.reset()}>reset here</button>
          </Text>
        </Box>

        <Box mb={4}>
          <Text fontWeight={600} fontSize={36}>
            {this.state.balance}
          </Text>
        </Box>
        <Box mb={1}>
          <Text fontSize={16}>Your Stake:</Text>
        </Box>

        <Box mb={3}>
          <Input
            type="text"
            defaultValue={this.state.stake}
            fontWeight={600}
            fontSize={24}
            onChange={e => {
              if (!isNaN(parseInt(e.target.value)))
                this.setState({ stake: parseInt(e.target.value) })
            }}
          />
        </Box>
        <Box>
          <Text fontSize={16}>You won:</Text>
        </Box>
        <Flex>
          <MultiplierBox
            style={{ background: '#e2ffe2' }}
            onClick={() => this.onWinLose(1)}
          >
            1x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#9aff9a' }}
            onClick={() => this.onWinLose(2)}
          >
            2x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#5dff5d' }}
            onClick={() => this.onWinLose(3)}
          >
            3x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#00ff00' }}
            onClick={() => this.onWinLose(5)}
          >
            5x
          </MultiplierBox>
        </Flex>
        <Box>
          <Text fontSize={16}>You lose:</Text>
        </Box>
        <Flex>
          <MultiplierBox
            style={{ background: '#fff18c' }}
            onClick={() => this.onWinLose(-1)}
          >
            -1x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#ffdc8c' }}
            onClick={() => this.onWinLose(-2)}
          >
            -2x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#ffb18c' }}
            onClick={() => this.onWinLose(-3)}
          >
            -3x
          </MultiplierBox>
          <MultiplierBox
            style={{ background: '#ff8c8c' }}
            onClick={() => this.onWinLose(-5)}
          >
            -5x
          </MultiplierBox>
        </Flex>
      </Flex>
    )
  }
}
